function setMin () {
  document.querySelectorAll('[data-action="set-min"]').forEach(function (element) {
    element.addEventListener('change', function (event) {
      const targetId = event.target.dataset.target
      const target = document.getElementById(targetId)
      target.min = event.target.value
    })
    const targetId = element.dataset.target
    const target = document.getElementById(targetId)
    target.min = element.value
    element.removeAttribute('data-action')
  })
}

setMin()

document.querySelectorAll('[data-action="formset-add-row"]').forEach(function (element) {
  element.addEventListener('click', function (event) {
    const formPrefix = event.target.dataset.formPrefix
    const totalFormInput = document.getElementById(`id_${formPrefix}-TOTAL_FORMS`)
    document.getElementById(event.target.dataset.target).insertAdjacentHTML('beforebegin',
      document.getElementById(`${formPrefix}-extra`).innerHTML.replace(/__prefix__/g, totalFormInput.value)
    )
    setMin()
    totalFormInput.value++
  })
})
